.react-datepicker-wrapper {
    width: auto!important;
    margin-left: 1.2rem;
}

.react-datepicker {
    border: 1px solid #d4dae3!important;
    font-family: 'Helvetica', sans-serif !important;
    border-radius: 10px !important;
    overflow: hidden;
}

.react-datepicker .react-datepicker__navigation {
    width: 30px;
    height: 30px;
    margin: 0;
}

.react-datepicker .react-datepicker__day,
.react-datepicker .react-datepicker__day-name{
    font-size: 14px;
    width: 28px;
    line-height: 28px;
    margin-left: 3px;
    margin-right: 3px;
    position: relative;
    z-index: 1;
}

.react-datepicker .react-datepicker__day-name {
    font-weight: 600;
    color: #a0aec0;
}

.react-datepicker .react-datepicker__current-month {
    font-size: 18px;
}

.react-datepicker .react-datepicker__header  {
    border-radius: 8px 8px 0 0;
    background: #ffffff;
    overflow: hidden;
    border-bottom: none;
}

.react-datepicker .react-datepicker__triangle:before {
    border-bottom-color: #223361!important;
}

.react-datepicker .react-datepicker__triangle:after {
    border-bottom-color: #ffffff!important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #718096!important;
    border-width: 2px 2px 0 0!important;
    top: 8px!important;
}

.react-datepicker__day--selected,
.react-datepicker__month-text--in-selecting-range
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #223361 !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
    background: #E2E4EB !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    color: #ffffff!important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #232323!important;
}

.react-datepicker__month-container {
    min-width: 250px;
}

.react-datepicker__day-names {
    margin-top: 8px;
}

.react-datepicker__day.react-datepicker__day--range-start,
.react-datepicker__day.react-datepicker__day--range-end,
.react-datepicker__day.react-datepicker__day--selected {
    border-radius: 100%;
}

.react-datepicker__day.react-datepicker__day--range-start,
.react-datepicker__day.react-datepicker__day--selecting-range-start,
.react-datepicker__day--keyboard-selected {
    background-color: #223361 !important;
}

.react-datepicker__day.react-datepicker__day--range-end,
.react-datepicker__day.react-datepicker__day--selecting-range-end {
    background-color: #223361cc !important;
}

.react-datepicker__day.react-datepicker__day--range-start:after,
.react-datepicker__day.react-datepicker__day--selecting-range-start:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 100%;
    background-color: #223361;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--range-end):not(:nth-child(7n)):before,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-end):not(:nth-child(7n)):before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: -60%;
    background: #E2E4EB;
    z-index: -1;
}

.react-datepicker__week .react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selecting-range-end {
    border-radius: 100% !important;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end),
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--selecting-range-end) {
    color: #223361;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    content: none !important;
}

.datepicker-operations .react-datepicker-wrapper {
  margin-left: 0;
}
