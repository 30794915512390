.react-tooltip {
    background-color: #ffffff!important;
    color: #232323!important;
    border-radius: 8px!important;
    padding: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 15%);
    font-size: 1.2rem!important;
    opacity: 1!important;
    max-width: 50%;
    text-align: center;
}
