html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
    outline: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

html,
body {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

form {
    width: 100%;
}

#root + div {
    /*Скрывает не нужную кнопку от инструкции*/
    display: none;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #223361;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
}

.modal-notifications p, .modal-notifications span, .modal-notifications ul, .modal-notifications li {
  margin: revert;
  padding: revert;
  border: revert;
  font-size: revert;
  vertical-align: revert;
}
